import { useCallback, useEffect, useReducer } from "react";
import { ApiFetch } from "../helpers/ApiFectch";

/**
 * Interfaces
 */
interface State<T> {
   data?: T
   isLoading?: boolean
   error?: Error,
}

type Action<T> = // acciones para el reducer
   | { type: "loading" }
   | { type: "fetched"; payload: T }
   | { type: "error"; payload: Error }


const useFetch = <T extends unknown>(url: string, options?: RequestInit): State<T> => {

   // Creamos reducer interno para mantener la lógica de estado separada
   const initialState: State<T> = { data: undefined, isLoading: false, error: undefined };

   const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
      switch (action.type) {
         case "loading":
            return { ...initialState, isLoading: true };
         case "fetched":
            return { ...initialState, data: action.payload };
         case "error":
            return { ...initialState, error: action.payload };
         default:
            return state;
      }
   };
   const [state, dispatch] = useReducer(fetchReducer, initialState);

   const fetchData = useCallback(async () => {
      dispatch({ type: "loading" });
      try {
         const data = await ApiFetch<T>(url, options);
         dispatch({ type: "fetched", payload: data });
      } catch (error) {
         dispatch({ type: "error", payload: error as Error });
      }
   }, [url]);

   useEffect(() => {
      if (!url) return; // validamos si se envía la URL
      void fetchData();
      return () => {
      };
   }, [url]);

   return state;
};

export default useFetch;
