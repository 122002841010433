import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import { Alert } from "@mui/material";

const ErrorBoundary = withErrorBoundary(({ children }) => {
   const [error] = useErrorBoundary((error, errorInfo) => console.error(error, errorInfo));
   if (error) {
      return (
         <Alert severity="error">Ha ocurrido un error al intentar visualizar el componente, por favor contactar con su
            administrador de aplicación.</Alert>);
   }
   return <div>{children}</div>;
});

export default ErrorBoundary;
