import { StringMap } from "quill";
import * as Emoji from "quill-emoji";
import { Quill } from "react-quill";

import "./assets/quill-emoji.css";
import { TagsModule } from "./modules/TagsModule";
import { QuillHtmlModule } from "./modules/QuillHtmlModule";
import { QuillCustomImgModule } from "./modules/QuillCustomImgModule";

/**
 * Registro de módulos
 */
Quill.register("modules/tags", TagsModule);
Quill.register("modules/html", QuillHtmlModule);
Quill.register("modules/image-ext", QuillCustomImgModule);
Quill.register("modules/emoji", Emoji);

/**
 * Una función que retorna una configuración dinámica de los módulos
 * @param tags
 */
const renderModules = ({ tags = [] } = {}): StringMap => {

   let toolbarOptions = [
      [{ "font": [] }, { "header": [1, 2, 3, 4, 5, 6, false] }, { "size": ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ "align": [] }, "blockquote", "code-block", "emoji"],
      [{ "color": [] }, { "background": [] }],
      [{ "list": "ordered" }, { "list": "bullet" },
         { "script": "sub" }, { "script": "super" },
         { "indent": "-1" }, { "indent": "+1" }],
      ["link", "image", "image-ext", "clean"],
      ["html"]
   ];

   // @ts-ignore
   if (tags.length > 0) toolbarOptions.push([{ "tags": tags }]);

   let _modules: StringMap = {
      toolbar: {
         container: toolbarOptions,
         handlers: { "html": true, "image-ext": true }
      },
      "tags": true,
      "html": true,
      "image-ext": true,
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true
   };

   if (tags.length > 0) {
      _modules.toolbar["handlers"]["tags"] = true;
      _modules["tags"] = true;
   }

   return _modules;
};

export default renderModules;
