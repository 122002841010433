import React from "react";
import { Alert } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { route } from "../helpers";
import { AppConstans } from "../helpers";
import { LoaderSpinner } from "./LoaderSpinner";
import { ContentEditableProps, InputType, INotificaciones } from "../types";
import MyAccordion from "./custom/MyAccordion";
import ContentEditable from "./ContentEditable";

/**
 * Genera un acordeón con la lista de parámetros html
 * @constructor
 */
const ListNotificaciones: React.FC = () => {

   /**
    * Hooks
    */
   const { data, isLoading, error } = useFetch<INotificaciones[]>(route(AppConstans.URL.NOTIFICACIONES));

   /**
    * Render Principal
    */
   return <>
      {isLoading && <LoaderSpinner loading />}
      {error && <Alert severity="error">Error al intentar cargar el contenido del componente.</Alert>}
      {!isLoading && data?.map((item: INotificaciones, index: number) => {
         const props: ContentEditableProps = {
            route: route(AppConstans.URL.NOTIFICACIONES_UPDATE),
            tags: "tags", item, editables: [
               { field: "nombre", label: "Nombre", type: InputType.Text },
               { field: "asunto", label: "Asunto", type: InputType.TextArea },
               { field: "mensaje", label: "Plantilla de correo electrónico", type: InputType.Editor },
               { field: "activo", label: "Activa", type: InputType.Check }
            ]
         };
         return <MyAccordion key={index} title={item.nombre} content={<ContentEditable {...props} />} />;
      })}
   </>;
};

export default ListNotificaciones;