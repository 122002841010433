import { Accordion, styled } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const CaretDown = () => {
   return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               className="bi bi-caret-down" viewBox="0 0 16 16">
      <path
         d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
   </svg>;
};

/**
 * Componente de sobre-escritura para  @mui/material Accordion
 * @param title
 * @param content
 * @constructor
 */
const MyAccordion = ({ title, content }) => {

   const AccordionSummary = styled(MuiAccordionSummary)(() => ({
      border: "1px solid #e5e5e5",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      backgroundColor: "rgba(0, 0, 0, .03)"
   }));

   const AccordionDetails = styled(MuiAccordionDetails)(() => ({
      padding: "15px !important",
      border: "1px solid #e5e5e5",
      borderTop: "0px"
   }));

   /**
    * Render principal
    */
   return <Accordion>
      <AccordionSummary expandIcon={<CaretDown />}>{title}</AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
   </Accordion>;

};

export default MyAccordion;
