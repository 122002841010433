import { Grid } from "@mui/material"
import { SyncLoader } from "react-spinners"

interface Props {
  loading: boolean
}
export const LoaderSpinner = ({ loading }: Props) => {
  return (
    <div className="background-loader">
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <SyncLoader color="#003057" loading={loading} size={60} margin={10} />
      </Grid>
    </div>
  )
}
