/**
 *
 * @param quill
 * @constructor
 */
import { addIconToolbar } from "../helpers/Utils";

export const QuillHtmlModule = (quill) => {

   const toolbar = quill.getModule("toolbar");
   const SvtIconCode = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-code-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/></svg>`;

   quill.container.insertAdjacentHTML("afterbegin", `<div class="ql-editor ql-editor-html"><textarea class="ql-output-html"></textarea></div>`);

   const contentHtml = quill.container.querySelector(".ql-editor-html");
   const fieldTextArea = contentHtml.querySelector(".ql-output-html");

   /**
    * Asignamos los estilos por defecto al contenedor, oculto...
    */
   contentHtml.style.maxHeight = "500px";
   contentHtml.style.display = "none";
   contentHtml.style.background = "#f5f5f5";
   fieldTextArea.style.width = "100%";
   fieldTextArea.style.height = "400px";
   fieldTextArea.style.borderRadius = "12px";

   const updateHtmlOutput = () => {
      fieldTextArea.value = quill.root.innerHTML;
   };
   const handleChangeValueEditor = () => {
      quill.root.innerHTML = fieldTextArea.value
   }
   const addEventHandler = () => {
      const toogleHtmlEditor = () => {
         const contentEditor = quill.container.querySelector(".ql-editor:not(.ql-editor-html)");
         if (contentEditor.classList.contains("show-html")) {
            handleChangeValueEditor();
            contentEditor.classList.toggle("show-html");
            contentHtml.style.display = "none";
            contentEditor.style.display = "block";
         } else {
            contentEditor.classList.toggle("show-html");
            contentEditor.style.display = "none";
            contentHtml.style.display = "block";
         }
      };
      toolbar.addHandler("html", (value) => {toogleHtmlEditor()});
      fieldTextArea.addEventListener('change', handleChangeValueEditor);
   };

   quill.on("text-change", updateHtmlOutput);
   addIconToolbar(toolbar, SvtIconCode, "html");
   addEventHandler();

};


