import React from "react";
import { Alert } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { route } from "../helpers";
import { AppConstans } from "../helpers";
import { LoaderSpinner } from "./LoaderSpinner";
import { ContentEditableProps, InputType, IPatameters } from "../types";
import MyAccordion from "./custom/MyAccordion";
import ContentEditable from "./ContentEditable";

/**
 * Genera un acordeón con la lista de parámetros html
 * @constructor
 */
const ListParameters: React.FC = () => {

   /**
    * Hooks
    */
   const { data, isLoading, error } = useFetch<IPatameters[]>(route(AppConstans.URL.PARAMETROS));

   /**
    * Render Principal
    */
   return <>
      {isLoading && <LoaderSpinner loading />}
      {error && <Alert severity="error">Error al intentar cargar el contenido del componente.</Alert>}
      {!isLoading && data?.map((item: IPatameters, index: number) => {
         const props: ContentEditableProps = {
            route: route(AppConstans.URL.PARAMETROS_UPDATE),
            item, editables: [
               { field: "nombre", label: "Nombre", type: InputType.Text },
               { field: "descripcion", label: "Descripción", type: InputType.TextArea },
               { field: "valor", label: "Valor", type: InputType.Text },
               { field: "activo", label: "Activo", type: InputType.Check }
            ]
         };
         return <MyAccordion key={index} title={item.nombre} content={<ContentEditable {...props} />} />;
      })}
   </>;
};

export default ListParameters;