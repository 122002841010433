/**
 * Interfaces
 */
export interface IPatametersHtml {
   activo: boolean;
   codigo: string;
   descripcion: string;
   id: string;
   nombre: string;
   texto: string;
}

export interface IPatameters {
   activo: boolean;
   codigo: string;
   descripcion: string;
   id: string;
   nombre: string;
   valor: string;
}

export interface IMetaDatos {
   nombre: string;
   descripcion: string;
   mensaje: string;
   activo: boolean;
   isModal: boolean;
   id: string;
   codigo: string;
}

export interface INotificaciones {
   nombre: string;
   asunto: string;
   mensaje: string;
   tags: string;
   activo: boolean;
   id: string;
   codigo: string;
}


/**
 * Types
 */
export type InterfacesData = IPatametersHtml | IMetaDatos | INotificaciones | IPatameters

export type EditableType = {
   field: string,
   label: string,
   type: InputType
}

export type ContentEditableProps = {
   route: string
   tags?: string
   item: InterfacesData
   editables: EditableType[],
   method?: string
}

/**
 * Enums
 */
export enum InputType { Text, TextArea, Editor, Check }