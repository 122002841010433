import {EncryptStorage} from 'encrypt-storage';

export enum ItemStorage {
   tokenUser = 'us3r3x1sts',
   moduleUser = 'us3rM0dul3',
   metaDatosApplication = 'appl1cat10nM3taDat0s'
}

export const localStorage = new EncryptStorage('secret-key', {
   prefix: '@somostalentoApp'
});
