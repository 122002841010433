import React from "react";
import { Alert } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { route } from "../helpers";
import { AppConstans } from "../helpers";
import { LoaderSpinner } from "./LoaderSpinner";
import { ContentEditableProps, InputType, IMetaDatos } from "../types";
import MyAccordion from "./custom/MyAccordion";
import ContentEditable from "./ContentEditable";

/**
 * Genera un acordeón con la lista de meta datos
 * @constructor
 */
const ListMetaDatos: React.FC = () => {

   /**
    * Hooks
    */
   const { data, isLoading, error } = useFetch<IMetaDatos[]>(route(AppConstans.URL.META_DATOS));

   /**
    * Render Principal
    */
   return <>
      {isLoading && <LoaderSpinner loading />}
      {error && <Alert severity="error">Error al intentar cargar el contenido del componente.</Alert>}
      {!isLoading && data?.map((item: IMetaDatos, index: number) => {
         const props: ContentEditableProps = {
            route: route(AppConstans.URL.META_DATOS_UPDATE),
            item, editables: [
               { field: "nombre", label: "Nombre", type: InputType.Text },
               { field: "descripcion", label: "Descripción", type: InputType.TextArea },
               { field: "mensaje", label: "Contenido", type: InputType.Editor },
               { field: "activo", label: "Activo", type: InputType.Check },
               { field: "isModal", label: "Mostrar en modal", type: InputType.Check }
            ], method: 'PUT'
         };
         return <MyAccordion key={index} title={item.nombre} content={<ContentEditable {...props} />} />;
      })}
   </>;
};

export default ListMetaDatos;