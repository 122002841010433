import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import AppRoot from "./App";
import { Alert } from "@mui/material";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: AppRoot,
  errorBoundary(err, info, props) {
    console.error(err, info, props);
    return <Alert severity="error">Error en el rénder del micro-frontend <b>@ld/parameters-html</b>, por favor contactar con su administrador de aplicación.</Alert>;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
