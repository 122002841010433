import { addIconToolbar } from "../helpers/Utils";

/**
 * @param quill
 * @constructor
 */
export const QuillCustomImgModule = (quill) => {

   const tagNameOrigin = "image";
   const tagName = "image-ext";
   const toolbar = quill.getModule("toolbar");

   const SvtIconImage = `<svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 512 512"><path d="M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z"/></svg>`;
   const SvtIconImageExt = `<svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48z"/></svg>`;
   addIconToolbar(toolbar, SvtIconImage, tagNameOrigin);
   addIconToolbar(toolbar, SvtIconImageExt, tagName);

   const _HTML_ = `<div class="ql-image-ext__props ql-hidden">
      <span>Ancho:</span>
      <input type="number" class="ql-image-ext__input-width" placeholder="auto" min="0" max="100">
      <span>Alto:</span>
      <input type="number" class="ql-image-ext__input-height" placeholder="auto" min="0" max="100">
  </div>`;

   quill.container.querySelector(".ql-tooltip input")
      .insertAdjacentHTML("afterend", _HTML_);

   function imageHandler() {
      const tooltip = this.quill.theme.tooltip;
      const originalSave = tooltip.save;
      const originalHide = tooltip.hide;

      tooltip.save = function() {
         const range = this.quill.getSelection(true);
         const value = this.textbox.value;
         const width = this.quill.theme.tooltip.root.querySelector(".ql-image-ext__input-width")?.value;
         const height = this.quill.theme.tooltip.root.querySelector(".ql-image-ext__input-height")?.value;
         if (value) {
            this.quill.insertEmbed(range.index, "image", value, "user");
            if (width) this.quill.formatText(range.index, range.index + 1, "width", `${width}px`);
            if (height) this.quill.formatText(range.index, range.index + 1, "height", `${height}px`);

         }
      };
      tooltip.hide = function() {
         tooltip.save = originalSave;
         tooltip.hide = originalHide;
         tooltip.root.classList.remove("ql-image-ext");
         tooltip.hide();
      };

      tooltip.root.querySelectorAll("input").forEach(item => item.value = "");
      tooltip.edit("image");
      tooltip.textbox.placeholder = "Imagen url";
      tooltip.root.classList.add("ql-image-ext");
   }

   toolbar.addHandler(tagName, imageHandler);

};
