import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";

import renderModules from "./ReactQuillConfig";

/**
 * Types
 */
export interface ICustomEditor {
   value: string,
   handleEditorStateChange: Function
   delay?: number,
   tags: string[]
}

/**
 * Extraction of react-draft-wysiwyg component
 * @param value
 * @param handleEditorStateChange
 * @param delay
 * @param tags
 * @constructor
 */
const MyReactQuill = ({ value, handleEditorStateChange, delay = 1000, tags }: ICustomEditor) => {

   /**
    * Carga de hooks iniciales
    */
   const [editorValue, setEditorValue] = useState(value);
   const editorRef = useRef(null);

   /**
    * Efectos
    */
   useEffect(() => {
      const timer = setTimeout(() => {
         if (value !== editorValue) {
            handleEditorStateChange(editorValue);
         }
      }, delay);
      return () => clearTimeout(timer);
   }, [editorValue]);

   /**
    * Función para captura del evento onchange del editor.
    * @param currentValue
    */
   const handleChange = (currentValue) => {
      if (editorRef.current) {
         setEditorValue(currentValue);
      }
   };

   /**
    * Render Principal
    */
   return <div spellCheck={false}>
      <ReactQuill ref={editorRef}
                  value={editorValue}
                  onChange={handleChange}
                  placeholder={"Escribe algo genial..."}
                  modules={renderModules({tags})} />
   </div>;
};

export default MyReactQuill;
