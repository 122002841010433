import { ChangeEvent, useState } from "react";
import { Alert, Button } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

import { ContentEditableProps, EditableType, InputType } from "../types";
import { ApiFetchPost, ApiFetchPut } from "../helpers";
import { MyReactQuill } from "./custom/MyReactQuill";
import ErrorBoundary from "./withErrorBoundary";


/**
 * Componente que contiene el contenido editable dentro de cada acordeon, esto con el fin de aislar cada subcomponente.
 * @param route
 * @param item
 * @param editables
 * @param ApiFetchPost
 * @constructor
 */
const ContentEditable = ({ route, tags = "", item, editables, method = "POST" }: ContentEditableProps) => {

   /**
    * Definición de estados.
    */
   const [editableState, setEditableState] = useState({ ...item });
   const [loading, setLoading] = useState(false);
   const defaultStateMessage = { type: "success", message: "" };
   const [message, setMessage] = useState(defaultStateMessage);

   /**
    * Esta función permite realizar el render del input según el tipo de dato enviado.
    * @param editable
    */
   const renderInput = (editable: EditableType) => {
      switch (editable.type) {
         case InputType.Text:
            return <input
               className="form-control w-100"
               value={editableState[editable.field]}
               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setEditableState(state => ({ ...state, [editable.field]: event.target.value }));
               }} />;
         case InputType.TextArea:
            return <textarea
               className="form-control w-100"
               value={editableState[editable.field]}
               onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setEditableState(state => ({ ...state, [editable.field]: event.target.value }));
               }}></textarea>;
         case InputType.Editor:
            return <MyReactQuill
               value={editableState[editable.field]}
               delay={1000}
               tags={tags.length > 0 ? item[tags].split(";") : []}
               handleEditorStateChange={(value: string) => {
                  setEditableState(state => ({ ...state, [editable.field]: value }));
               }} />;
         case InputType.Check:
            return <input
               className="form-check-input"
               type="checkbox"
               checked={editableState[editable.field]}
               onChange={() => {
                  setEditableState(state => ({ ...state, [editable.field]: !editableState[editable.field] }));
               }} />;

         default:
            return;
      }
   };

   /**
    * Función para actualizar los datos
    */
   const saveDataItem = () => {
      setLoading(true);
      let fetch = ApiFetchPost;
      if (method === "PUT") fetch = ApiFetchPut;
      fetch<string>(route, editableState, { headers: new Headers({ "Content-Type": "text/plain" }) })
         .then(() => {
            setLoading(false);
            setMessage({ type: "success", message: "El registro fue actualizado correctamente." });
            setTimeout(() => setMessage(defaultStateMessage), 2000);
         }).catch(() => setMessage({ type: "error", message: "Error al intentar actualizar el registro." }));
   };

   /**
    * Render principal
    */
   return <ErrorBoundary>
      {editables.map((editable: EditableType) => {
         const key = `${item.id}-${editable.field}`;
         return <div className="form-group" key={key} style={{ marginTop: "10px" }}>
            <label className="fw-semibold">{editable.label}</label>
            {renderInput(editable)}
         </div>;
      })}
      <div className="form-group d-flex " style={{ marginTop: "10px" }}>
         <Button className="btn btn-primary me-3" variant="contained"
            onClick={() => saveDataItem()}
            disabled={loading}>
            {loading ? "Guardando..." : "Guardar Cambios"}
         </Button>
         {message.message && <Alert severity={message.type as AlertColor}>{message.message}</Alert>}
      </div>
   </ErrorBoundary>;

};

export default ContentEditable;