import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, SyntheticEvent, useState } from "react";

import ErrorBoundary from "./components/withErrorBoundary";
import ListParametersHtml from "./components/ListParametersHtml";
import ListMetaDatos from "./components/ListMetaDatos";
import ListNotificaciones from "./components/ListNotificaciones";
import ListParameters from "./components/ListParameters";

/**
 * Types
 */
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

/**
 * App principal
 * @constructor
 */
const App = () => {

  /**
   * Estados
   */
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (<Box style={{ padding: "5px" }}>{children}</Box>)}
      </div>
    );
  };

  return <>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Parámetros HTML" />
      <Tab label="Meta Datos" />
      <Tab label="Notificaciones" />
      <Tab label="Parámetros" />
    </Tabs>
    <TabPanel value={value} index={0}>
      <ErrorBoundary><ListParametersHtml /></ErrorBoundary>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <ErrorBoundary><ListMetaDatos /></ErrorBoundary>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <ErrorBoundary><ListNotificaciones /></ErrorBoundary>
    </TabPanel>
    <TabPanel value={value} index={3}>
      <ErrorBoundary><ListParameters /></ErrorBoundary>
    </TabPanel>
  </>;
};

export default App;