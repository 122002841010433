export const AppConstans = {
  URL: {
    PARAMETROS_HTML: "ParametroHtml/ParametrosHtml",
    PARAMETROS_HTML_UPDATE: "ParametroHtml/ParametrosHtml-Update",
    META_DATOS: "MetaDatos/MetaDatos",
    META_DATOS_UPDATE: "MetaDatos/MetaDatos-Update",
    NOTIFICACIONES: "Notificaciones/Notificaciones",
    NOTIFICACIONES_UPDATE: "Notificaciones/Notificacion-Update",
    PARAMETROS: "Parametro/ParametrosAplicacion",
    PARAMETROS_UPDATE: "Parametro/ParametroAplicacion-Update",
  }
};