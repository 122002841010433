/**
 * Permite sobre escribir el icono de un item del toolbar
 * @param toolbar
 * @param svgIcon
 * @param name
 * @param isList
 */
export const addIconToolbar = (toolbar, svgIcon, name, isList = false) => {
   if (toolbar) {
      let selector = `.ql-formats > .ql-${name}:not(.render-icon)`;
      if (isList) selector = `.ql-formats > .ql-${name} .ql-picker-label:not(.render-icon)`;
      const label = toolbar.container.querySelector(selector);
      if (label) {
         label.classList.add("render-icon");
         label.style.minWidth = "20px";
         label.innerHTML = svgIcon || "?";
      }
   }
};
